import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { backgroundBlack, border, mainBlue } from '../../global/css';
import BodyRow from './BodyRow';

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: scroll;
  box-sizing: border-box;
  padding-right: 8px;
  width: fit-content;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${border};
    border-radius: 36px;
  }
  &::-webkit-scrollbar-track {
    background: ${backgroundBlack};
  }
  ${(props) =>
    !!props.$noScroll &&
    css`
      overflow: visible;
      padding-right: 0;
    `}
  ${(props) =>
    !!props.$fullWidth &&
    css`
      width: 100%;
    `}
  ${(props) =>
    !!props.$hasBorder &&
    css`
      border: 1px solid #e8eef5;
      padding: 8px;
      box-sizing: border-box;
      border-radius: 6px;
    `}
  ${(props) =>
    !!props.$height &&
    css`
      height: ${`${props.$height}`};
      max-height: ${props.$height};
    `}
  ${(props) =>
    !!props.$maxHeight &&
    css`
      height: fit-content;
      max-height: ${props.$maxHeight};
    `};

  ${(props) =>
    !!props.$minHeight &&
    css`
      min-height: ${props.$minHeight};
    `}
`;

const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${border};
  box-sizing: border-box;
  display: flex;
  width: fit-content;
  background-color: ${backgroundBlack};
  position: sticky;
  top: 0;
  z-index: 3;
  ${(props) =>
    !!props.$autoWidth &&
    css`
      display: grid;
      grid-template-columns: ${!!props.$autoWidth && `${props.$type === 'check' || props.$type === 'radio' ? '72px' : ''} repeat(${props.$autoWidth}, 1fr)`};
      width: max-content;
      & > * {
        width: 100% !important;
      }
    `}
  ${(props) =>
    !!props.$hasTopBorder &&
    css`
      border-top: 1px solid ${border};
    `};
`;

const HeaderItem = styled.div`
  display: flex;
  width: 100%;
  white-space: nowrap;
  justify-content: center;
  column-gap: 8px;
  padding: ${(props) => (!!props.$hasSort ? '0 12px 0 32px' : '0 12px')};
  box-sizing: border-box;

  ${(props) =>
    !!props.$hasSort &&
    css`
      cursor: pointer;
    `}

  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 16px;
  color: ${mainBlue};
  height: 32px;
  align-items: center;
  border-right: 1px solid ${border};

  &:last-of-type {
    border-right: none;
  }
  & * {
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0px;
    line-height: 16px;
  }
  & > div {
    display: flex;
    align-items: center;
  }
  &:hover {
    & > div {
      opacity: 1;
    }
  }

  ${(props) =>
    !!props.$width &&
    css`
      width: ${props.$width};
      min-width: ${props.$width};
    `}
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: transparent;
  width: fit-content;
  overflow-x: hidden;
  overflow: visible;
  font-feature-settings: 'tnum';
  position: relative;
  ${(props) =>
    !!props.$autoWidth &&
    css`
      width: 100%;
    `}
`;

const BodyRowWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid ${border};

  ${(props) =>
    !!props.$autoWidth &&
    css`
      width: max-content !important;
    `}
  &:last-of-type {
    border-bottom: none;
  }
  ${(props) =>
    !!props.$selected &&
    css`
      background-color: #78828e15;
    `}
  ${(props) =>
    !!props.$fullWidth &&
    css`
      width: 100%;
    `}
`;

const BodyItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: ${(props) => (!!props.$padding ? props.$padding : '8px 4px')};
  box-sizing: border-box;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.2px;
  line-height: 20px;
  color: ${mainBlue};
  word-break: break-all;
  & * {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 16px;
  }

  ${(props) =>
    !!props.$width &&
    css`
      width: ${props.$width};
      min-width: ${props.$width};
    `}
`;

const BodyRowText = styled.p`
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 18px;
`;

const Table = (props) => {
  const {
    isFetching,
    fullWidth,
    maxHeight,
    height,
    hasBorder,
    header = [],
    list = [],
    onRowClick,
    dataId = 'id',
    type = '',
    selectedItemList = [],
    size,
    selectedSort = '',
    setSelectedSort = () => {},
    minHeight,
    autoWidth = 0,
    noScroll = false,
    dataCoverList = [],
    tableId = '',
    noDataText = '데이터가 없습니다.',
  } = props;

  const tableRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);

  useEffect(() => {
    const t = document.getElementById(`table_header_${tableId ?? ''}`);
    if (!!t) {
      setTableWidth(t.scrollWidth);
    }
  }, [tableRef.current, tableId, header]);

  const [dataList, setDataList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleRowClick = (data) => {
    if (!!onRowClick) onRowClick(data);
  };

  const handleSort = (e, id) => {
    if (!!e) {
      e.stopPropagation();
    }

    if (selectedSort.includes(id)) {
      const [, current] = selectedSort.split('-sort-');
      if (current === 'asc') {
        setSelectedSort('');
      } else {
        setSelectedSort(id + '-sort-asc');
      }
    } else {
      setSelectedSort(id + '-sort-desc');
    }
  };

  useEffect(() => {
    let timeout;
    if (!!isFetching) {
      timeout = setTimeout(() => {
        setIsLoading(() => true);
      }, 1000);
    } else {
      setIsLoading(() => false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isFetching]);

  useEffect(() => {
    if (!list || !list.length) return;
    setDataList([...list]);
  }, [list]);

  return (
    <TableWrapper ref={tableRef} $noScroll={!!noScroll} $height={height} $minHeight={minHeight} $maxHeight={maxHeight} $fullWidth={!!fullWidth} $hasBorder={!!hasBorder}>
      <HeaderWrapper id={`table_header_${tableId ?? ''}`} $type={type} $autoWidth={autoWidth} $hasTopBorder={!hasBorder}>
        {header.map((h, i) => {
          return (
            <HeaderItem $hasSort={!!h.sort} onClick={!!h.sort ? (e) => handleSort(e, h.value) : () => {}} key={i} $width={!!h.width ? h.width : ''}>
              <p>{h.title}</p>
            </HeaderItem>
          );
        })}
      </HeaderWrapper>
      <BodyWrapper $autoWidth={autoWidth}>
        {!isFetching && !!dataList.length ? (
          dataList.map((l) => {
            return (
              <BodyRowWrapper $autoWidth={autoWidth} $fullWidth={!!fullWidth} key={l[dataId]}>
                <BodyRow
                  tableWidth={!!tableWidth ? tableWidth : 0}
                  type={type}
                  autoWidth={autoWidth}
                  selected={!!onRowClick && selectedItemList.includes(l[dataId])}
                  fullWidth={!!fullWidth}
                  hasClick={!!onRowClick}
                  onClick={() => handleRowClick(l)}
                >
                  {header.map((h, j) => {
                    return (
                      <BodyItem $width={!!h.width ? h.width : ''} key={j} $padding={!!size && size === 'small' ? '4px 8px' : ''}>
                        {!!h.render
                          ? h.render(l)
                          : !!dataCoverList.length && !!dataCoverList.filter((d) => d.value === l[h.value] && d.header === h.value).length
                          ? dataCoverList.filter((d) => d.value === l[h.value] && d.header === h.value)[0].title
                          : !!l[h.value] || l[h.value] === 0
                          ? l[h.value]
                          : '-'}
                      </BodyItem>
                    );
                  })}
                </BodyRow>
              </BodyRowWrapper>
            );
          })
        ) : !isFetching && !list.length && !dataList.length ? (
          //데이터 없음
          <BodyRowText>{noDataText}</BodyRowText>
        ) : (
          !!isLoading && (
            //로딩중
            <BodyRowText>로딩중</BodyRowText>
          )
        )}
      </BodyWrapper>
    </TableWrapper>
  );
};

export default Table;
