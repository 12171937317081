import { ThemeProvider, createTheme } from '@mui/material';
import styled from 'styled-components';
import { mainBlue, mainSkyBlue } from './global/css';
import MainPage from './pages/MainPage';
import './reset.css';

function App() {
  if (process.env.NODE_ENV === 'production') {
    console = window.console || {};
    console.log = function no_console() {};
    console.warn = function no_console() {};
    console.error = function () {};
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: mainBlue,
        contrastText: mainSkyBlue,
      },
    },
  });

  return (
    <AppWrapper>
      <ThemeProvider theme={theme}>
        <MainPage />
      </ThemeProvider>
    </AppWrapper>
  );
}

export default App;

const AppWrapper = styled.div``;
