import styled from 'styled-components';
import { border, borderAngle } from '../global/css';

const BorderDiv = ({ children, fullWidth, scroll }) => {
  return (
    <BorderDivWrapper $fullWidth={fullWidth} $scroll={scroll}>
      <div>
        <div />
        <div />
      </div>
      <div>{children}</div>
      <div>
        <div />
        <div />
      </div>
    </BorderDivWrapper>
  );
};

export default BorderDiv;

const BorderDivWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid ${border};
  padding: 2px;
  width: ${(props) => (!!props.$fullWidth ? '100%' : 'fit-content')};
  height: fit-content;
  overflow: ${(props) => (!!props.$scroll ? 'auto' : 'visible')};
  & > div {
    display: flex;
    position: absolute;
  }

  & > div:first-of-type {
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
      width: 8px;
      height: 8px;
      border-top: 1px solid ${borderAngle};
      &:first-of-type {
        border-left: 1px solid ${borderAngle};
      }
      &:last-of-type {
        border-right: 1px solid ${borderAngle};
      }
    }
  }

  & > div:last-of-type {
    bottom: -1px;
    left: -1px;
    width: calc(100% + 2px);
    justify-content: space-between;
    & > div {
      display: flex;
      width: 8px;
      height: 8px;
      border-bottom: 1px solid ${borderAngle};
      &:first-of-type {
        border-left: 1px solid ${borderAngle};
      }
      &:last-of-type {
        border-right: 1px solid ${borderAngle};
      }
    }
  }

  & > div:nth-of-type(2) {
    z-index: 2;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
  }
`;
