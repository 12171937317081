import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { differenceInMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import BorderDiv from '../components/BorderDiv';
import LineChartBox from '../components/LineChartBox';
import Table from '../components/table/Table';
import { mainInstance } from '../global/axiosSetting';
import { backgroundBlack, border, mainBlue, mainPurple, mainSkyBlue } from '../global/css';
import { standardReferenceList } from '../global/data';
import { getInterpolationData, getNormalizedData, getRandomColor, standardXAxisUnit } from '../global/utils';

const MainPage = () => {
  const [referenceList, setReferenceList] = useState([]);
  const [selectedReferenceId, setSelectedReferenceId] = useState('');
  const [referenceDataList, setReferenceDataList] = useState([]);
  const [referenceSeries, setReferenceSeries] = useState([]);
  const [referenceDisabledList, setReferenceDisabledList] = useState([]);
  const [displayReferenceSeries, setDisplayReferenceSeries] = useState([]);
  const [displayReferenceList, setDisplayReferenceList] = useState([]);

  const [brewIdList, setBrewIdList] = useState([]);
  const [brewDataList, setBrewDataList] = useState([]);
  const [brewDataSeries, setBrewDataSeries] = useState([]);
  const [displayBrewdataSeries, setDisplayBrewDataSeries] = useState([]);
  const [displayBrewDataList, setDisplayBrewDataList] = useState([]);
  const [brewDisabledSeries, setBrewDisabledSeries] = useState([]);
  const [avgFlag, setAvgFlag] = useState(true);

  const [brewIsLoading, setBrewIsLoading] = useState(false);
  const [allBrewData, setAllBrewData] = useState({});
  const [selectedBrewId, setSelectedBrewId] = useState();
  const [tableBody, setTableBody] = useState([]);

  const [referenceRadioType, setReferenceRadioType] = useState('hide');
  const [brewRadioType, setBrewRadioType] = useState('hide');

  const getStringDate = () => {
    const now = new Date();
    return `${now.getFullYear()}${now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : `${now.getMonth()}`}${now.getDate() < 10 ? `0${now.getDate()}` : `${now.getDate()}`}${
      now.getHours() < 10 ? `0${now.getHours()}` : `${now.getHours()}`
    }${now.getMinutes() < 10 ? `0${now.getMinutes()}` : `${now.getMinutes()}`}${now.getSeconds() < 10 ? `0${now.getSeconds()}` : `${now.getSeconds()}`}`;
  };

  const handleReferenceSelect = (data) => {
    setDisplayBrewDataSeries([]);
    setSelectedReferenceId(data.target.value);
  };

  const handleBrewSelect = (data) => {
    setTableBody([]);

    setSelectedBrewId(data.target.value);
  };

  const saveAvg = () => {
    setReferenceSeries((prev) => [
      ...prev,
      ...displayBrewdataSeries
        .filter((b) => b.label.includes('Average'))
        .map((s) => {
          return {
            ...s,
            label: 'Average' + getStringDate(),
          };
        }),
    ]);

    if (referenceDataList.length < displayBrewdataSeries.filter((b) => b.label.includes('Average'))[0].data.length) {
      const arr = [];
      for (let i = 0; i < displayBrewdataSeries.filter((b) => b.label.includes('Average'))[0].data.length; i++) {
        arr.push({ customIdx: i });
      }

      setReferenceDataList(arr);
    }
  };

  const handleAvg = (id, status) => {
    setAvgFlag(true);
    if (!brewDisabledSeries.includes(id) && !status) {
      setBrewDisabledSeries((prev) => [...prev, id]);
    } else {
      setBrewDisabledSeries((prev) => [...prev.filter((p) => p !== id)]);
    }
    if (brewRadioType === 'delete') {
      setBrewDataSeries((prev) => [...prev.filter((p) => p.label !== id)]);
    }
  };

  const handleReferenceLegend = (id, status) => {
    if (!referenceDisabledList.includes(id) && !status) {
      setReferenceDisabledList((prev) => [...prev, id]);
    } else {
      setReferenceDisabledList((prev) => [...prev.filter((p) => p !== id)]);
    }
    if (referenceRadioType === 'delete') {
      setReferenceSeries((prev) => [...prev.filter((p) => p.label !== id)]);
    }
  };

  useEffect(() => {
    if (!brewDataSeries || !brewDataSeries.length || !avgFlag) return;

    const filteredBrewDataSeries = [...brewDataSeries.filter((b) => !brewDisabledSeries.includes(b.label))];

    const avgSeriesData = [];

    const brewLength = filteredBrewDataSeries.length;
    const listLength = brewDataList.length;

    for (let i = 0; i < listLength; i++) {
      let flag = false;
      let sum = 0;
      for (let j = 0; j < brewLength; j++) {
        const value = filteredBrewDataSeries[j]?.data[i];

        if (!!value || value === 0) {
          sum += value;
        } else {
          flag = true;
        }
      }
      if (!!flag) {
        break;
      } else {
        avgSeriesData.push(sum / brewLength);
      }
    }

    const avgSeries = {
      backgroundColor: mainSkyBlue,
      borderColor: mainSkyBlue,
      data: avgSeriesData,
      label: `Average(${brewLength})`,
    };

    const disabledSeries = [...brewDataSeries.filter((b) => brewDisabledSeries.includes(b.label))];
    setDisplayBrewDataSeries([avgSeries, ...filteredBrewDataSeries, ...disabledSeries]);

    setAvgFlag(false);
  }, [brewDisabledSeries, brewDataSeries, avgFlag]);

  useEffect(() => {
    if (!referenceSeries || !referenceSeries.length) return;

    const filteredReferenceDataSeries = [...referenceSeries.filter((b) => !referenceDisabledList.includes(b.label))];

    const disabledSeries = [...referenceSeries.filter((b) => referenceDisabledList.includes(b.label))];

    setDisplayReferenceSeries([...filteredReferenceDataSeries, ...disabledSeries]);
  }, [referenceDisabledList, referenceSeries]);

  useEffect(() => {
    if (!displayReferenceSeries || !displayReferenceSeries.length || !referenceDataList || !referenceDataList.length) {
      setDisplayReferenceList([]);
      return;
    }

    const max = Math.max(...displayReferenceSeries.filter((d) => !referenceDisabledList.includes(d.label)).map((d) => d.data.length));

    // const refList = [
    //   ...referenceDataList.slice(0, max).map((r) => {
    //     return {
    //       ...r,
    //       customIdx: r.customIdx,
    //     };
    //   }),
    // ];

    const refList = [];

    for (let i = 0; i < max; i++) {
      refList.push({
        customIdx: i,
      });
    }

    setDisplayReferenceList(refList);
  }, [displayReferenceSeries, referenceDataList, referenceDisabledList]);

  useEffect(() => {
    if (!displayBrewdataSeries || !displayBrewdataSeries.length || !brewDataList || !brewDataList.length) {
      setDisplayBrewDataList([]);
      return;
    }

    const max = Math.max(...displayBrewdataSeries.filter((d) => !brewDisabledSeries.includes(d.label)).map((d) => d.data.length));

    const first = brewDataList[0];

    const timeUpdatedBrewDataList = [];

    // let last = null;

    for (const d of brewDataList) {
      // if (!!last) {
      //   console.log('----', differenceInMinutes(d.dateTime, last.dateTime));
      // }

      timeUpdatedBrewDataList.push({
        ...d,
        customIdx: differenceInMinutes(d.dateTime, first.dateTime),
      });
      // last = d;
    }
    setDisplayBrewDataList([...timeUpdatedBrewDataList.slice(0, max)]);
  }, [displayBrewdataSeries, brewDataList, brewDisabledSeries]);

  useEffect(() => {
    mainInstance({
      method: 'get',
      url: '/api/tank/reference/list',
    })
      .then((res) => {
        console.log('reference list =>', res);
        setReferenceList(res.data.data);
      })
      .catch((error) => {
        console.log('reference list error =>', error);
      });
  }, []);

  useEffect(() => {
    if (!selectedReferenceId) return;

    const selectedReference = referenceList.find((r) => r.refId === selectedReferenceId);

    if (!selectedReference || !selectedReference.refId) return;
    mainInstance({
      method: 'get',
      url: '/api/tank/reference/data',
      params: {
        referenceVersion: selectedReference.refVersion,
        referenceId: selectedReference.refId,
        step: 'all',
      },
    })
      .then((res) => {
        console.log('reference data =>', res);

        setReferenceDataList(
          [...res.data.data]
            .sort((a, b) => (a.idx > b.idx ? 1 : -1))
            .map((d, i) => {
              return {
                ...d,
                customIdx: i,
              };
            })
        );

        const data = [...res.data.data].sort((a, b) => (a.idx > b.idx ? 1 : -1)).map((r) => r.temperature);

        const normalizedData = getNormalizedData(data);

        const standardNormalizedData = getNormalizedData(standardReferenceList);

        const series = [
          {
            label: '기준',
            data: getInterpolationData(standardNormalizedData, standardXAxisUnit),
            borderColor: mainPurple,
            backgroundColor: mainPurple,
          },
          {
            label: selectedReference.refName,
            data: getInterpolationData(normalizedData, standardXAxisUnit),
            borderColor: mainBlue,
            backgroundColor: mainBlue,
          },
        ];

        setReferenceSeries(series);
      })
      .catch((error) => {
        console.log('reference data error =>', error);
      });

    mainInstance({
      method: 'get',
      url: '/api/tank/brew/data/ids',
      params: {
        referenceVersion: selectedReference.refVersion,
        referenceId: selectedReference.refId,
      },
    })
      .then((res) => {
        console.log('id list =>', res);
        setBrewIdList(res.data.data);
      })
      .catch((error) => {
        console.log('id list error =>', error);
      });
  }, [selectedReferenceId, referenceList]);

  const getDataList = async () => {
    setBrewIsLoading(() => true);
    const getDatas = await Promise.all(
      brewIdList.map(async (id) => {
        try {
          const res = await mainInstance.get('/api/tank/brew/data', {
            params: {
              brewId: id,
            },
          });
          console.log('getdata => ', res);
          return {
            ...res.data,
          };
        } catch (error) {
          console.log(error);
        }
      })
    );

    console.log('getDatas => ', getDatas);
    let obj = {};

    for (const b of getDatas) {
      obj[b.data[0].brewId] = b.data;
    }
    setAllBrewData(obj);
    let brewList = [];
    const brewSeries = [];

    for (let d of getDatas) {
      const sortedData = [...d.data]
        .sort((a, b) => (a.idx > b.idx ? 1 : -1))
        .map((d, i) => {
          return {
            ...d,
            customIdx: i,
          };
        });

      console.log('??', sortedData);

      const diff = differenceInMinutes(sortedData[1].dateTime, sortedData[0].dateTime);
      console.log('diff', diff);

      let data = [...sortedData].map((r) => r.tempBot);

      if (diff > 1) {
        data = getInterpolationData(data, diff);
      }

      const normalizedData = getNormalizedData(data);

      const color = getRandomColor();

      const series = {
        label: sortedData[0].brewId,
        data: normalizedData,
        borderColor: color,
        backgroundColor: color,
      };
      if (!brewList || !brewList.length || brewList.length < sortedData.length) {
        brewList = [...sortedData];
      }
      brewSeries.push(series);
    }
    setBrewIsLoading(() => false);

    setAvgFlag(() => true);
    setBrewDataList(brewList);
    console.log('brewList =>', brewList);
    setBrewDataSeries(brewSeries);
    console.log('brew series =>', brewSeries);
  };

  useEffect(() => {
    if (!brewIdList || !brewIdList.length) {
      setBrewDataList([]);
      setBrewDataSeries([]);
      return;
    }
    getDataList();
  }, [brewIdList]);

  useEffect(() => {
    if (!selectedBrewId || !allBrewData || !Object.keys(allBrewData).length) return;
    const selected = allBrewData[selectedBrewId];

    setTableBody(selected);
  }, [selectedBrewId, allBrewData]);

  return (
    <MainPageWrapper>
      <BorderDiv fullWidth>
        <ContentWrapper>
          <ChartWrapper>
            <LineChartBox
              fullWidth
              title='레퍼런스'
              radioType={referenceRadioType}
              setRadioType={setReferenceRadioType}
              topRightData={
                <FormControl sx={{ minWidth: '160px' }} size='small'>
                  <InputLabel sx={{ color: border }} size='small' id='reference-select'>
                    레퍼런스 선택
                  </InputLabel>
                  <Select
                    sx={{
                      color: mainBlue,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: border,
                      },
                      ':hover': {
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: `${border}60`,
                        },
                      },
                      '& .MuiSvgIcon-root': {
                        color: 'white',
                      },
                    }}
                    size='small'
                    labelId='reference-select-label'
                    id='reference-select'
                    value={selectedReferenceId}
                    label='레퍼런스 선택'
                    onChange={handleReferenceSelect}
                  >
                    {referenceList.map((r) => {
                      return (
                        <MenuItem key={r.refId} value={r.refId}>
                          {r.refName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              }
              onChangeLegend={handleReferenceLegend}
              series={displayReferenceSeries}
              list={[...displayReferenceList].map((r) => r.customIdx)}
            />
          </ChartWrapper>
        </ContentWrapper>
      </BorderDiv>
      <BorderDiv fullWidth>
        <ContentWrapper>
          <ChartWrapper>
            <LineChartBox
              radioType={brewRadioType}
              setRadioType={setBrewRadioType}
              onChangeLegend={handleAvg}
              fullWidth
              title='측정 데이터'
              isLoading={brewIsLoading}
              topRightData={
                <Button disabled={!displayBrewdataSeries || !displayBrewdataSeries.length} onClick={saveAvg} variant='outlined'>
                  평균 추가
                </Button>
              }
              series={displayBrewdataSeries}
              list={[...displayBrewDataList].map((r) => r.customIdx)}
            />
          </ChartWrapper>
        </ContentWrapper>
      </BorderDiv>
      <BorderDiv fullWidth>
        <ContentWrapper>
          <TableWrapper>
            {!!brewIdList.length && (
              <SearchWrapper>
                <span></span>
                <FormControl sx={{ minWidth: '240px' }} size='small'>
                  <InputLabel sx={{ color: border }} size='small' id='reference-select'>
                    측정데이터 선택
                  </InputLabel>
                  <Select
                    sx={{
                      color: mainBlue,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: border,
                      },
                      ':hover': {
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: `${border}60`,
                        },
                      },
                      '& .MuiSvgIcon-root': {
                        color: 'white',
                      },
                    }}
                    size='small'
                    labelId='reference-select-label'
                    id='reference-select'
                    value={selectedBrewId || ''}
                    label='측정데이터 선택'
                    onChange={handleBrewSelect}
                  >
                    {brewIdList.map((r) => {
                      return (
                        <MenuItem key={r} value={r}>
                          {r}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </SearchWrapper>
            )}
            <TableBox>
              {!!selectedBrewId && !!tableBody && !!tableBody.length && (
                <Table maxHeight={`${window.innerHeight - 112}px`} dataId='dateTime' fullWidth header={tableHeaer} list={tableBody} />
              )}
            </TableBox>
          </TableWrapper>
        </ContentWrapper>
      </BorderDiv>
    </MainPageWrapper>
  );
};

export default MainPage;

const tableHeaer = [
  {
    title: 'dbVersion',
    value: 'dbVersion',
    width: '80px',
  },
  {
    title: 'co2Bot',
    value: 'co2Bot',
    width: '80px',
  },
  {
    title: 'co2Top',
    value: 'co2Top',
    width: '80px',
  },
  {
    title: 'dateTime',
    value: 'dateTime',
    width: '160px',
  },
  {
    title: 'humiIn',
    value: 'humiIn',
    width: '80px',
  },
  {
    title: 'humiOut',
    value: 'humiOut',
    width: '80px',
  },
  {
    title: 'humiOutside',
    value: 'humiOutside',
    width: '88px',
  },
  {
    title: 'motorRpm',
    value: 'motorRpm',
    width: '80px',
  },
  {
    title: 'stage',
    value: 'stage',
    width: '80px',
  },
  {
    title: 'tankId',
    value: 'tankId',
    width: '80px',
  },
  {
    title: 'tempBot',
    value: 'tempBot',
    width: '80px',
  },
  {
    title: 'tempIn',
    value: 'tempIn',
    width: '80px',
  },
  {
    title: 'tempOut',
    value: 'tempOut',
    width: '80px',
  },
  {
    title: 'tempOutside',
    value: 'tempOutside',
    width: '88px',
  },
  {
    title: 'tempTop',
    value: 'tempTop',
    width: '80px',
  },
  {
    title: 'valveB',
    value: 'valveB',
    width: '80px',
  },
  {
    title: 'valveTBot',
    value: 'valveTBot',
    width: '80px',
  },
  {
    title: 'valveTTop',
    value: 'valveTTop',
    width: '80px',
  },
  {
    title: 'valveWBot',
    value: 'valveWBot',
    width: '80px',
  },
  {
    title: 'valveWTop',
    value: 'valveWTop',
    width: '80px',
  },
  {
    title: 'weight0',
    value: 'weight0',
    width: '80px',
  },
  {
    title: 'weight1',
    value: 'weight1',
    width: '80px',
  },
];

const MainPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${backgroundBlack};
  column-gap: 8px;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 20px;
  row-gap: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
`;

const ChartWrapper = styled.div``;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  /* overflow-x: auto; */
  row-gap: 8px;
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const TableBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 12px;
  box-sizing: border-box;
`;
