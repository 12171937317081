export const standardXAxisUnit = 10;

export const getNormalizedData = (list) => {
  const normalizedList = [];

  const max = Math.max(...list);
  const min = Math.min(...list);

  for (const l of list) {
    normalizedList.push(((l - min) / (max - min)) * 100);
  }

  return normalizedList;
};

export const getInterpolationData = (list, diff) => {
  const resultList = [];

  let last = null;

  for (const item of list) {
    if (!!last) {
      for (let i = 1; i < diff; i++) {
        const v = ((item - last) * i) / diff + last;
        resultList.push(v);
      }
    }

    resultList.push(item);

    last = item;
  }

  return resultList;
};

export const getRandomColor = () => {
  const colorCode = '#' + Math.round(Math.random() * 0xffffff).toString(16);
  return colorCode;
};
