export const standardReferenceList = [
  19.5, 19.5, 19.5, 19.6, 19.6, 19.7, 19.7, 19.7, 19.8, 19.9, 20.0, 20.5, 20.7, 20.9, 21.1, 21.4, 21.7, 21.9, 22.1, 22.3, 22.5, 22.7, 22.8, 23.1, 23.4, 23.7, 24.0, 24.3, 24.7,
  25.0, 25.3, 25.5, 24.5, 24.5, 24.5, 24.5, 24.6, 24.6, 24.6, 24.7, 24.7, 24.8, 24.9, 24.9, 24.9, 25.0, 25.1, 25.1, 25.2, 25.2, 25.2, 25.2, 25.2, 25.1, 25.1, 25.1, 25.1, 25.1,
  25.1, 25.0, 25.0, 24.9, 24.8, 24.7, 24.7, 24.7, 24.7, 24.6, 24.6, 24.5, 24.5, 24.3, 24.3, 24.3, 24.2, 24.2, 24.2, 24.1, 24.1, 24, 23.9, 23.9, 23.8, 23.7, 23.6, 23.5, 23.5, 23.5,
  23.4, 23.4, 23.3, 23.3, 23.2, 23.2, 23.1, 23.0, 21.0, 21.5, 22.0, 22.0, 22.0, 22.0, 22.1, 22.2, 22.3, 22.4, 22.5, 22.6, 22.7, 22.8, 22.9, 23.0, 23.1, 23.2, 23.3, 23.4, 23.5,
  23.7, 24.0, 24.3, 24.6, 24.9, 25.2, 25.5, 25.5, 25.5, 24.5, 24.5, 24.6, 24.7, 24.8, 24.9, 25.0, 25.1, 25.2, 25.3, 25.3, 25.3, 25.3, 25.2, 25.2, 25.2, 25.1, 25.1, 25.0, 25.0,
  24.9, 24.8, 24.7, 24.6, 24.5, 24.4, 24.3, 24.2, 24.1, 24.0, 23.9, 23.6, 23.4, 23.3, 23.2, 23.1, 23.0, 23.0, 23.0, 23.0, 21.0, 22.0, 22.0, 22.0, 22.0, 22.0, 22.1, 22.1, 22.1,
  22.1, 22.2, 22.2, 22.2, 22.2, 22.3, 22.4, 22.5, 22.6, 22.7, 22.8, 22.9, 23.0, 23.1, 23.2, 23.3, 23.4, 23.5, 23.6, 23.7, 23.7, 23.7, 23.8, 23.9, 23.9, 24.0, 24.0, 24.0, 24.1,
  24.1, 24.1, 24.2, 24.2, 24.2, 24.3, 24.3, 24.3, 24.3, 24.2, 24.2, 24.2, 24.2, 24.1, 24.1, 24.1, 24.1, 24, 24, 23.9, 23.8, 23.7, 23.7, 23.7, 23.6, 23.5, 23.5, 23.4, 23.3, 23.2,
  23.2, 23.1, 23.1, 23.1, 23.0, 23.0, 22.8, 22.7, 22.6, 22.5,
];
