import axios from 'axios';

const setIntercepter = (instance, type) => {
  instance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const createInstance = (type) => {
  const instance = axios.create({});

  setIntercepter(instance, type);

  instance.defaults.baseURL = 'https://api.cosmo-m.co.kr';
//  instance.defaults.baseURL = 'http://3.36.43.248:9000';
  return instance;
};

export const mainInstance = createInstance();
