import { CategoryScale, Chart, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from 'chart.js';
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import styled, { css, keyframes } from 'styled-components';
import zoomPlugin from 'chartjs-plugin-zoom';
import { mainBlue, mainSkyBlue } from '../global/css';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

const LoadingAnimation = keyframes`
  0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (!!props.$height ? `${props.$height}px` : '200px')};
  width: 100%;
  & > span {
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: ${mainSkyBlue};
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: ${LoadingAnimation} 1s linear infinite;
  }
`;

const ChartBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  box-sizing: border-box;
  width: 100%;
  min-width: 400px;
  border-radius: 12px;
  height: fit-content;
  flex: 1;

  ${(props) =>
    !!props.$fullWidth &&
    css`
      min-width: 100%;
    `}
  & > div:first-of-type {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 12px;
    box-sizing: border-box;
    & > div {
      display: flex;
      column-gap: 8px;
      align-items: center;
      & > span {
        color: ${mainSkyBlue};
      }
    }
  }
`;

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, zoomPlugin);

const LineChartBox = (props) => {
  const {
    radioType,
    setRadioType,
    onChangeLegend = () => {},
    beginAtZero,
    isLoading,
    yInterval,
    height,
    yLabel,
    list = [],
    series = [],
    title,
    border = '',
    topRightData,
    fullWidth,
  } = props;

  const graphRef = useRef(null);
  const lineRef = useRef(null);

  const [graphWidth, setGraphWidth] = useState(0);

  const options = {
    animation: {
      duration: 0,
    },
    scales: {
      y: {
        min: 0,
        max: 100,
        beginAtZero: !!beginAtZero,
        ticks: {
          callback: function (value, index, values) {
            return value + (!!yLabel ? yLabel : '');
          },
          stepSize: !!yInterval ? yInterval : 1,
        },
      },
      x: {
        ticks: {
          callback(value, index, ticks) {
            return list[value] + '분';
          },
        },
      },
    },
    responsive: false,
    maintainAspectRatio: true,
    elements: {
      point: {
        pointStyle: false,
      },
    },
    plugins: {
      legend: {
        onClick: (e, legendItem, legend) => {
          onChangeLegend(legendItem.text, legendItem.hidden);

          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          if (ci.isDatasetVisible(index)) {
            ci.hide(index);
            legendItem.hidden = true;
          } else {
            ci.show(index);
            legendItem.hidden = false;
          }
        },
      },
      decimation: {
        enabled: true,
        algorithm: 'lttb',
        samples: 100,
      },
      tooltip: {
        enabled: true,
        intersect: false,
        mode: 'index',
        caretSize: 0,
        callbacks: {
          title: function (tooltipItem) {
            return tooltipItem[0].label + '분';
          },
          // label: function (tooltipItem) {
          //   con
          //   return series[0].label + ': ' + tooltipItem.formattedValue + (!!yLabel ? yLabel : '');
          // },
        },
      },
      zoom: {
        zoom: {
          // wheel: {
          //   enabled: true,
          //   modifierKey: 'ctrl',
          // },
          drag: {
            enabled: true,
          },
          mode: 'x',
          speed: 0.5,
        },
        // pan: {
        //   enabled: true,
        //   mode: 'x',
        //   speed: 0.5,
        // },
      },
    },
  };

  const data = {
    labels: list,
    datasets: series,
  };

  useEffect(() => {
    if (!graphRef || !graphRef.current) return;

    const handleResize = () => {
      try {
        lineRef.current.resize(graphRef.current.offsetWidth - 30, 280);
      } catch (err) {
        console.log(err);
      }
      setGraphWidth(graphRef.current.offsetWidth - 30);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [graphRef]);

  const resetZoom = () => {
    lineRef.current.resetZoom();
  };

  return (
    <ChartBoxWrapper ref={graphRef} $height={height} $fullWidth={!!fullWidth} $border={border}>
      <div>
        <div>
          <span>{title}</span>
          {!!lineRef && !!lineRef.current && !!series && !!series.length && (
            <Button sx={{ padding: 0, margin: 0, minWidth: 'fit-content' }} size='small' variant='outlined' onClick={resetZoom}>
              <ZoomOutIcon sx={{ height: '18px', width: '18px' }} />
            </Button>
          )}
        </div>
        <div>
          <FormControl>
            <RadioGroup row aria-labelledby='graphClickType' defaultValue='hide' value={radioType} onChange={(e) => setRadioType(e.target.value)} name='graphClickType'>
              <FormControlLabel
                on
                sx={{ color: mainBlue }}
                value='hide'
                control={
                  <Radio
                    sx={{
                      color: mainBlue,
                      '&.Mui-checked': {
                        color: mainBlue,
                      },
                    }}
                  />
                }
                label='숨김'
              />
              <FormControlLabel
                sx={{ color: mainBlue }}
                value='delete'
                control={
                  <Radio
                    sx={{
                      color: mainBlue,
                      '&.Mui-checked': {
                        color: mainBlue,
                      },
                    }}
                  />
                }
                label='삭제'
              />
            </RadioGroup>
          </FormControl>
          {topRightData}
        </div>
      </div>
      {!!isLoading ? (
        <LoadingWrapper $height={height}>
          <span />
        </LoadingWrapper>
      ) : (
        <Line ref={lineRef} key={`${title}-${graphWidth}`} width={graphWidth} height={280} options={options} data={data} />
      )}
    </ChartBoxWrapper>
  );
};

export default LineChartBox;
